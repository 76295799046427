<template>
	<div class="template-page">

		<!-- <div class="template" v-if="originTemplate">
			<div class="template__title-container" v-bind:style="{
				'background' : templateBackground(originTemplate, 1)
			}">
				<div class="template__title">{{originTemplate.title || "Template"}}</div>
			</div>
	
			<div class="template__properties"	v-bind:style="{
				'border-color' : templateBackground(originTemplate, 0.5)
			}">
				
			</div>
		</div> -->

		<div class="template__title" v-if="originTemplate" v-bind:style="{
			background: templateBackground(originTemplate, 1),
			color: originTemplate.cellTextColor,
		}">
			{{ originTemplate.title || "Template" }}
		</div>

		<div class="template" v-if="originTemplate">
			<SlotSettingsForm v-model="form" :disableList="disableList" isTemplate/>
		</div>
	</div>
</template>

<script>
import { addOpacityToColor } from '../../scripts/color';
import SlotSettingsForm from '../forms/slot-settings/SlotSettingsForm.vue';
import SlotSettingsFormInstance from '../forms/slot-settings/formInstance';

export default {
	name: 'TemplateSettings',
	components: {
		SlotSettingsForm
	},
	computed: {
		originTemplate(){
			let templateId = this.$route.params.templateId
			let origin = this.$store.getters.calendarSlotTemplateById(templateId)
			if (origin){
				this.form = new SlotSettingsFormInstance(JSON.parse(JSON.stringify(origin)))
			}
			return origin
		}
	},
	data() {
		return {
			form: {},
			disableList: [
				'template',
				'date',
				'time',
				'timezone-difference',
			]
		};
	},

	methods: {
		addOpacityToColor: addOpacityToColor,
		goToTemplateMenu(){
			this.$router.push({name:'calendar-settings-template-menu'})
		},
		templateBackground(template, opacity) {
			if (!template || !template.cellBackgroundColor)
				return "lavender"
			let isRGB = template.cellBackgroundColor.includes("rgb")
			let isHex = template.cellBackgroundColor.includes("#")
			if (isRGB || isHex)
				return addOpacityToColor(template.cellBackgroundColor, opacity)
			return template.cellBackgroundColor
		},
		submit(){

		}
	}
};
</script>

<style scoped>

.template__title-container{
	padding: 7px 6px;
}

.template__title{
	width: 100%;
	height: 36px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
}

.template__properties{
	/* height: 100%; */
	border: 2px solid;
	border-top: none;
}

.template__header {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    padding: 0 5px;

    background: lightgrey;

	display: flex;
	justify-content: space-between;

	height: 32px;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
}

.template-page{
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-top: 32px;
    padding: 24px 16px;
}

.header__title {
    padding: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.header__button{
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>